import React from "react";
import "../styles/footer.css";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="flex flex-col tablet:flex-row justify-between items-center tablet:px-[68px] py-[18px] text-[#FFFFFF99] bg-[#111748] w-full">
        <p className="whitespace-nowrap">
          All rights reserved © 2024 - Planetbeyond
        </p>
        <p>Developed by - Planetbeyond</p>
      </div>
    </footer>
  );
};

export default Footer;
