import React from "react";
import "../styles/letsChat.css";
import headphone from "../assets/svgs/let's-chat/headphone.svg";
import arrow from "../assets/svgs/let's-chat/arrow.svg";
import mouse from "../assets/svgs/let's-chat/mouse.svg";
import facebook from "../assets/icons/facebook.svg";
import linkedIn from "../assets/icons/linkedIn.svg";
import mail from "../assets/icons/mail.svg";
import {
  CONTACT_US_URL,
  FACEBOOK_URL,
  LINKEDIN_URL,
  MAIL_URL,
} from "../data/Data";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import SectionSubTitle from "../reusable/SectionSubTitle";

const mediaLinks = [
  { image: facebook, url: FACEBOOK_URL },
  { image: linkedIn, url: LINKEDIN_URL },
  { image: mail, url: MAIL_URL },
];

const handleContactUsClicked = () => {
  window.open(CONTACT_US_URL, "_blank");
};

const mouseDiv = (
  <div className=" absolute aspect-w-1 aspect-h-1 top-[11.5%] -z-10 laptop:left-[16%]  laptop:block hidden">
    <img
      src={mouse}
      className="  laptop:pl-[25px]  pl-0 laptop:w-[100%] tablet:w-[80%] min-w-[20px] w-[45%]"
      alt=""
    />
  </div>
);

const arrowDiv = (
  <div className="aspect-w-1 aspect-h-1 laptop:mt-16 tablet:mt-14 mt-8">
    <img
      src={arrow}
      className="  laptop:pl-[25px]  pl-0 laptop:w-[100%] tablet:w-[80%] min-w-[20px] w-[60%]"
      alt=""
    />
  </div>
);

const togetherTextDiv = (
  // <div className="text-[#111748] font-[900]">Together</div>
  <SectionSubTitle
    baseAnimationDelayInSec={1}
    className="text-[#111748] font-[900]"
    text={"Together"}
  />
);

const contactUsSection = (
  <div className="">
    <div className=" laptop:text-[27px] tablet:text-[23px] text-[21px] whitespace-nowrap  tablet:mt-0 mt-[10px] mb-[30px]  mr-16">
      <span className=" text-[#11174899] ">Feel free to </span>
      <span
        onClick={handleContactUsClicked}
        className="underline text-[#FFC641] cursor-pointer"
      >
        Contact Us
      </span>
    </div>
  </div>
);

const mediaLinksSection = (
  <div className="flex flex-col gap-14 ">
    {mediaLinks.map((mediaLink, i) => (
      <div key={i} className={`${i === 0 ? "" : ""}`}>
        <a href={mediaLink.url} target="_blank" rel="noreferrer">
          <img src={mediaLink.image} alt="" className="size-6" />
        </a>
      </div>
    ))}
  </div>
);

const AddressSection = (
  <div className="flex items-start justify-between   tablet:gap-20 laptop:gap-32 mt-8">
    <div className="  mobile:mx-16 mx-8">{mediaLinksSection}</div>
    <div className="flex flex-col items-center mobile:flex-row mobile:items-start   w-full tablet:gap-[105px] mobile:gap-[50px] gap-[30px] ">
      <div className="tablet:text-[22px]  text-[16px] text-[#11174899]  w-[80%] mobile:w-[24%]">
        <p className="font-[500] tablet:text-[25px] text-[18px] text-[#111748CC] tablet:mb-4 mb-2">
          Pakistan
        </p>
        <p className="mb-2">
          2nd floor, Farid Business Park, Service Rd East, I-10/3, Islamabad
          Capital Territory
        </p>
        <p>(051) 8488133</p>
      </div>
      <div className="tablet:text-[22px]  text-[16px] text-[#11174899]  w-[80%] mobile:w-[24%]">
        <p className="font-[500] tablet:text-[25px] text-[18px] text-[#111748CC] tablet:mb-4 mb-2">
          Dubai
        </p>
        <p className="mb-2">
          2401-62, Prime Tower, Business Bay, Sheikh Zayed Road, Dubai, UAE
        </p>
        <p>+971 50 710 4858</p>
      </div>
      <div className="tablet:text-[22px]  text-[16px] text-[#11174899]  w-[80%] mobile:w-[24%]">
        <p className="font-[500] tablet:text-[25px] text-[18px] text-[#111748CC] tablet:mb-4 mb-2">
          United Kingdom
        </p>
        <p className="mb-2">7 Upton Park, Slough, United Kingdom, SL1 2DA.</p>
        {/* <p>(051) 8488133</p> */}
      </div>
    </div>
  </div>
);

const LetsChat = () => {
  return (
    <section
      id="letsChat"
      className="tablet:mt-[138px] mt-[69px] mb-[38px] relative"
    >
      <div className="flex justify-center laptop:block pr-16 laptop:pr-0">
        <div className="flex items-center laptop:space-x-14 uppercase leading-none section-title-font ">
          <div id="borderDivLeft" className="laptop:block hidden">
            ORK
          </div>
          <div className="text-[#111748] font-[900] laptop:ml-0 ml-14 whitespace-nowrap">
            {" "}
            Let's Work
          </div>
          {/* <SectionSubTitle
            className="text-[#111748] font-[900]  laptop:ml-0 ml-14 whitespace-nowrap"
            text={"Let's Work"}
          /> */}
          <div className="aspect-w-1 aspect-h-1 ml-10">
            <img
              src={headphone}
              className=" laptop:w-[100%] tablet:w-[80%] min-w-[45px] w-[60%] "
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="flex-grow">
        <div className="flex items-base tablet:justify-end justify-center mobile:pr-0 tablet:pr-28 laptop:pr-0   ">
          <div className="">{arrowDiv}</div>
          <div>
            <div className="laptop:ml-[4.5rem]  uppercase section-title-font">
              {togetherTextDiv}
            </div>
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {contactUsSection}
            </AnimateOnDisplay>
          </div>
          <div
            id="borderDivRight"
            className="laptop:block hidden ml-12 section-title-font uppercase"
          >
            Tog
          </div>
        </div>
      </div>
      {mouseDiv}

      <AnimateOnDisplay
        animationClass={"snap-in"}
        screenPercentHeightToStartAnimation={90}
        className={"opacity-0"}
        animateEveryTime={false}
      >
        {AddressSection}
      </AnimateOnDisplay>
    </section>
  );
};
export default LetsChat;
